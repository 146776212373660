import { useState, useMemo, useEffect } from "react";
import {
  Box,
  Flex,
  Spacer,
  VStack,
  HStack,
  Button,
  Center,
  Text,
  Select,
} from "@chakra-ui/react";
import {
  useEthers,
  shortenAddress,
  useContractFunction,
  useContractCall,
} from "@usedapp/core";
import { forEach, find } from "lodash";
import { utils, BigNumber, Contract, ethers } from "ethers";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

import { Buffer } from "buffer";
import useSWR from "swr";

const switchNetwork = async () => {
  if (window.ethereum) {
    try {
      await window.ethereum?.request({
        id: "1",
        jsonrpc: "2.0",
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x64",
            chainName: "xDai",
            rpcUrls: ["https://dai.poa.network"],
            blockExplorerUrls: ["https://blockscout.com/poa/xdai"],
            nativeCurrency: {
              name: "xDai",
              symbol: "XDAI",
              decimals: 18,
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  }
};

// https://dkp-claim-server.vercel.app/
const useConfig = () => {
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const { data, error } = useSWR(
    `https://dkp-claim-server.vercel.app/api/events`,
    fetcher
  );
  return {
    config: data,
    isLoading: !error && !data,
    isError: error,
  };
};

// query = [poolId, account]
const useDKPBalance = (contract, query) => {
  function fetcher(...query) {
    // console.log({ query });
    const [poolId, account] = query;
    return contract.earned(account, poolId);
  }

  const { data, error } = useSWR(query, fetcher);
  return {
    balance: data ? data : BigNumber.from("0"),
    isLoading: !error && !data,
    isError: error,
  };
};
// eventClaimed
const useEventClaimed = (contract, query) => {
  function fetcher(...query) {
    const [poolId, eventId, account] = query;
    return contract.eventClaimed(poolId, eventId, account);
  }

  const { data, error } = useSWR(query, fetcher);
  return {
    claimed: data,
    isLoading: !error && !data,
    isError: error,
  };
};

const getDKPAndPoolIdRelation = (config) => {
  const id2dkp = {};
  const dkp2id = {};
  forEach(config, (value, key) => {
    id2dkp[value.poolId] = key;
    dkp2id[key] = value.poolId;
  });
  // console.log({ id2dkp, dkp2id });
  return { id2dkp, dkp2id };
};

function hashToken(account, dkpAmount) {
  return Buffer.from(
    utils
      .solidityKeccak256(["address", "uint256"], [account, dkpAmount])
      .slice(2),
    "hex"
  );
}

function hashTokenWithParse(account, dkpAmount) {
  return Buffer.from(
    utils
      .solidityKeccak256(
        ["address", "uint256"],
        [account, utils.parseEther(String(dkpAmount))]
      )
      .slice(2),
    "hex"
  );
}

const getWhitelist = (_whitelist) => {
  const whitelist = {};
  forEach(_whitelist, (value, key) => {
    const dkp = String(value);
    const account = String(key).toLowerCase();
    whitelist[account] = dkp;
  });

  return whitelist;
};

const getWhitelistByConfig = async (config, poolId, eventId) => {
  const relation = getDKPAndPoolIdRelation(config);
  const dkpName = relation.id2dkp[poolId];
  // https://dkp-claim-server.vercel.app/api/whitelist?dkpName=dkp1&eventId=0
  const _whitelist = await fetch(
    `https://dkp-claim-server.vercel.app/api/whitelist?dkpName=${dkpName}&eventId=${eventId}`
  );
  const whitelist = await _whitelist.json();

  return getWhitelist(whitelist);
};

function App() {
  const [activateError, setActivateError] = useState("");
  const [selectedPool, setSelectedPool] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [canClaimDKP, setCanClaimDKP] = useState(0);

  const { activateBrowserWallet, account, deactivate, library } = useEthers();

  const dkpInterface = new utils.Interface([
    "function claimDKP(uint256 poolId,uint256 eventId,address account,uint256 dkpAmount,bytes32[] memory proof)",
    "event DKPClaimed(uint256 poolId, uint256 eventId, uint256 amount)",
    "function earned(address account, uint256 pool) public view returns (uint256)",
    "function eventClaimed(uint256 poolId, uint256 eventId, address account) public view returns (bool)",
  ]);
  const dkpContractAddress = "0x97193AD067EC41cA16119D9b3077745414DDaD8E";
  const dkpContract = new Contract(dkpContractAddress, dkpInterface);

  const readDKPContract = new Contract(
    dkpContractAddress,
    dkpInterface,
    ethers.getDefaultProvider("https://dai.poa.network")
  );

  const { balance: dkpBalance } = useDKPBalance(readDKPContract, [
    selectedPool,
    account,
  ]);
  const { claimed } = useEventClaimed(readDKPContract, [
    selectedPool,
    selectedEvent,
    account,
  ]);

  const { config } = useConfig();

  const relation = useMemo(() => {
    return getDKPAndPoolIdRelation(config);
  }, [config]);

  const { state, send } = useContractFunction(dkpContract, "claimDKP", {
    transactionName: "DKPClaimed",
  });

  const showCanClaim = async (poolId, eventId) => {
    const whitelist = await getWhitelistByConfig(config, poolId, eventId);
    return Object.keys(whitelist).indexOf(String(account).toLowerCase()) === -1
      ? "0"
      : whitelist[String(account).toLowerCase()];
  };

  useEffect(() => {
    async function set() {
      if (
        !Number.isNaN(parseInt(selectedPool)) &&
        !Number.isNaN(parseInt(selectedEvent))
      ) {
        const dkp = await showCanClaim(selectedPool, selectedEvent);
        setCanClaimDKP(dkp);
      }
    }

    set();
  }, [selectedPool, selectedEvent]);

  const activate = async () => {
    setActivateError("");
    await switchNetwork();
    activateBrowserWallet();
  };

  return (
    <Box>
      <Flex justify="space-between" p="10">
        <Text fontSize="xl">⚔Claim DKP</Text>
        <HStack>
          {account ? (
            <Text>{shortenAddress(account)} </Text>
          ) : (
            <Button
              colorScheme="teal"
              onClick={async () => {
                await activate();
              }}
            >
              🍚 Connect
            </Button>
          )}
          <Button colorScheme="teal" onClick={() => deactivate()}>
            Disconnect
          </Button>
        </HStack>
      </Flex>
      <Center mt="20vh">
        <VStack>
          {/* <Text>2333</Text> */}
          {/* <Text>your dkps is: {Number.isNaN(parseInt(selectedPool)) && account ? BigNumber.from(readDKPContract.earned(account, selectedPool)).toString() : 0}</Text> */}
          <Select
            placeholder="Select One Pool"
            onChange={(e) => {
              setSelectedPool(parseInt(e.target.value));
            }}
          >
            {config
              ? Object.keys(config).map((v) => {
                  return (
                    <option key={v} value={config[v].poolId}>
                      {v}
                    </option>
                  );
                })
              : null}
          </Select>
          <Select
            placeholder="Select One Event"
            onChange={(e) => {
              setSelectedEvent(parseInt(e.target.value));
            }}
          >
            {!Number.isNaN(parseInt(selectedPool))
              ? find(config, function (pool) {
                  return String(pool.poolId) === String(selectedPool);
                }).events.map((v) => {
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  );
                })
              : null}
          </Select>
          {!Number.isNaN(parseInt(selectedPool)) &&
          !Number.isNaN(parseInt(selectedEvent)) &&
          claimed === false ? (
            <>
              <Text>
                You can claim {canClaimDKP} {' '}
                {relation.id2dkp[selectedPool]}
              </Text>

              <Button
                colorScheme="teal"
                // disabled={
                //   !(parseInt(showCanClaim(selectedPool, selectedEvent)) > 0)
                // }
                onClick={async () => {
                  if (account) {
                    const whitelist = await getWhitelistByConfig(
                      config,
                      selectedPool,
                      selectedEvent
                    );
                    console.log(whitelist)
                    // return
                    const merkleTree = new MerkleTree(
                      Object.entries(whitelist).map((token) =>
                        hashTokenWithParse(...token)
                      ),
                      keccak256,
                      { sortPairs: true }
                    );

                    const canClaimDKP = utils.parseEther(
                      await showCanClaim(selectedPool, selectedEvent)
                    );

                    const proof = merkleTree.getHexProof(
                      hashToken(String(account).toLowerCase(), canClaimDKP)
                    );
                    console.log("proof:", proof);
                    // console.log({ selectedPool,
                    //   selectedEvent})
                    //   return

                    // dkp3 is pool 2
                    send(
                      BigNumber.from(selectedPool),
                      BigNumber.from(selectedEvent),
                      account,
                      canClaimDKP,
                      proof
                    );
                  }
                }}
              >
                ⚔ {' '} Claim
              </Button>
            </>
          ) : null}

          {dkpBalance ? (
            <Text>
              my {relation.id2dkp[selectedPool]}:{" "}
              {utils.formatEther(dkpBalance)}
            </Text>
          ) : null}
        </VStack>
      </Center>
    </Box>
  );
}

export default App;
